import React, { HTMLAttributes } from "react";
//@ts-ignore
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

type TArrow = "up" | "down" | "left" | "right";

interface IArrow extends HTMLAttributes<HTMLOrSVGElement> {
  type: TArrow;
  fill?: string;
  width?: number | string;
  height?: number | string;
}

const rotate: Record<TArrow, string> = {
  up: "0deg",
  right: "90deg",
  down: "180deg",
  left: "270deg",
};

export const ArrowSvg = ({ type, ...props }: IArrow) => (
  <Arrow {...props} style={{ rotate: rotate[type], ...props.style }} />
);
