export interface IColors {
  backgroundMain: string;
  blue: string;
  borderDark: string;
  green: string;
  green150: string;
  green500: string;
  grey: string;
  grey100: string;
  grey150: string;
  grey400: string;
  lightGrey: string;
  lightGrey300: string;
  lightGrey400: string;
  orange: string;
  orange500: string;
  placeholder: string;
  red: string;
  textGrey: string;
  textGrey200: string;
  textGrey400: string;
  textAccent: string;
  textMain: string;
  white: string;
}

export const Colors: IColors = {
  backgroundMain: "#F5F9F8",
  blue: "#007AFF",
  borderDark: "#3c3c435b",
  green: "#219653",
  green150: "#aee6c628",
  green500: "#2979524D",
  grey: "#B3BEB8",
  grey100: "#f3f3f3",
  grey150: "#ecebeb",
  grey400: "#C7C7C742",
  lightGrey: "#E3E9E7",
  lightGrey300: "#f5f6f6",
  lightGrey400: "#e3e9e740",
  orange: "#FB9231",
  orange500: "#FB923133",
  placeholder: "#B3BEB8",
  red: "#FF3B30",
  textGrey: "#5F6E67",
  textGrey200: "#5f6e673e",
  textGrey400: "#939393",
  textAccent: "#297952",
  textMain: "#274335",
  white: "#FFFFFF",
};
