import dayjs from "dayjs";
import {
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from "./constants";

// import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY } from "../constants/calendar";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const updateLocale = require("dayjs/plugin/updateLocale");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const weekday = require("dayjs/plugin/weekday");

dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.updateLocale("en", {
  weekStart: 1,
});

// TODO: ADD TS
class Calendar {
  constructor() {
    this.DaysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    this.DaysOfWeekEn = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    this.DaysOfWeekFull = [
      SUNDAY,
      MONDAY,
      TUESDAY,
      WEDNESDAY,
      THURSDAY,
      FRIDAY,
      SATURDAY,
    ];
    this.Months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];
    //Устанавливаем текущий месяц, год
    const d = new Date();
    this.currMonth = d.getMonth();
    this.currYear = d.getFullYear();
    this.currDayOfMonth = d.getDate();
    this.currDayOfWeek = d.getDay();
  }

  nextMonth() {
    if (this.currMonth == 11) {
      this.currMonth = 0;
      this.currYear = this.currYear + 1;
    } else {
      this.currMonth = this.currMonth + 1;
    }
    this.showcurr();
  }

  previousMonth() {
    if (this.currMonth == 0) {
      this.currMonth = 11;
      this.currYear = this.currYear - 1;
    } else {
      this.currMonth = this.currMonth - 1;
    }
    this.showcurr();
  }

  showcurr() {
    this.showMonth(this.currYear, this.currMonth);
  }

  //текст дня недели
  getWeekDayRu(day) {
    return this.DaysOfWeekEn[day];
  }

  //текст дня недели полностью
  getWeekDayFull(day) {
    const result = this?.DaysOfWeekFull[day];
    return result?.charAt && result
      ? result?.charAt(0)?.toUpperCase() + result.slice(1)
      : "";
  }

  // Первый день недели в выбранном месяце
  getFirstDayOfMonth(y = this.currYear, m = this.currMonth) {
    return new Date(y, m, 7).getDay();
  }

  // Последний день выбранного месяца
  getLastDateOfMonth(y = this.currYear, m = this.currMonth) {
    return new Date(y, m + 1, 0).getDate();
  }

  // Последний день предыдущего месяца
  getLastDayOfLastMonth(y = this.currYear, m = this.currMonth) {
    return m == 0
      ? new Date(y - 1, 11, 0).getDate()
      : new Date(y, m, 0).getDate();
  }

  // Возаращает обьект дни недели с датами
  // getDaysOfWeek(selectedDay) {
  //   const result = {};
  //   const daysOfWeekDefault = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

  //   daysOfWeekDefault.forEach((n, index) => {
  //     result[n] = dayjs(selectedDay).weekday(index);
  //   });

  //   return result;
  // }
}

export const calendar = new Calendar();
