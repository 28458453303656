import React, { FC, useEffect, useMemo, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import { IParticipantsAttendanceOfDay } from "@interfaces/interface";
import { TextFont } from "@components/ui/TextFont";
import { Colors } from "../../../constants/colors";
import { ArrowSvg } from "@components/ui/ArrowSvg";
import { calendar } from "@utils/calendar";
import { RowParticipant, WIDTH_BLOCK } from "./RowParticipant";
import localeData from "dayjs/plugin/localeData";
import { Row } from "./Row";
import { BlockTime } from "./BlockTime";

export const AMOUNT_VIEW_HOURS = 12;
export const HOUR_TO_SCROLL = 8;
export const DEVIATION = 60;
export const arrHours = [...Array(24).keys()];
export const heightBlockEvent = 36;
require("dayjs/locale/ru");
dayjs.extend(localeData);
dayjs.locale("ru");

interface IProps {
  time: { startTime: string; endTime: string };
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
  participants: IParticipantsAttendanceOfDay[];
  participantLight: string;
  handleHourSlotPress: (hour: number) => void;
}

export const Activity: FC<IProps> = ({
  currentDate,
  setCurrentDate,
  participants,
  participantLight,
  time,
  handleHourSlotPress,
}) => {
  const refView = useRef(null);

  useEffect(() => {
    refView?.current?.scrollTo(HOUR_TO_SCROLL * WIDTH_BLOCK, 0);
  }, [refView]);

  const isLightColumnNumber = useMemo(
    () => participants?.findIndex((i) => i.user.id === participantLight),
    [participantLight, participants]
  );

  return (
    <section>
      <StContentDiv>
        <StCalendarWrapDiv>
          <StBlockHeaderDiv>
            <StButtonIconBtn
              onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}
            >
              <ArrowSvg fill={Colors.textMain} type="left" />
            </StButtonIconBtn>
            <TextFont type="bold" size={16} weight="700">
              {`${calendar?.getWeekDayFull(
                currentDate.day()
              )}, ${currentDate.format("DD MMMM")}`}
            </TextFont>
            <StButtonIconBtn
              onClick={() => setCurrentDate(currentDate.add(1, "day"))}
            >
              <ArrowSvg fill={Colors.textMain} type="right" />
            </StButtonIconBtn>
          </StBlockHeaderDiv>

          <StBlockActivityDiv ref={refView}>
            <StActivityWrapDiv>
              <StBlockAttendanceDiv>
                {participants?.map((i) => (
                  <RowParticipant key={i.user.id} events={i.meetings} />
                ))}
              </StBlockAttendanceDiv>
              <StBlockTableDiv>
                {arrHours.map((hour, index) => (
                  <StRowTabDiv
                    isNotCursor={hour === 24}
                    onClick={() => handleHourSlotPress(hour)}
                  >
                    <Row
                      rows={participants?.length}
                      time={time}
                      isLightColumnNumber={isLightColumnNumber}
                      key={index}
                      hour={hour}
                      date={currentDate}
                    />
                  </StRowTabDiv>
                ))}
              </StBlockTableDiv>
              <BlockTime time={time} />
            </StActivityWrapDiv>
          </StBlockActivityDiv>
        </StCalendarWrapDiv>
      </StContentDiv>
    </section>
  );
};

const StContentDiv = styled.div`
  display: flex;
  border-radius: 10px;
`;
const StCalendarWrapDiv = styled.div`
  width: 95%;
  background-color: ${Colors.white};
  margin-left: 5px;
  border-radius: 7px;
`;
const StBlockHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-bottom: 1px solid ${Colors.lightGrey};
  height: 30px;
`;
const StButtonIconBtn = styled.button`
  display: flex;
  align-items: center;
  width: 27px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: 0.3s ease;
  &:hover {
    transition: 0.4s ease;
    border: 1px solid ${Colors.placeholder};
    background-color: ${Colors.lightGrey};
  }
`;
const StBlockActivityDiv = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 10px;
`;
const StActivityWrapDiv = styled.div`
  position: relative;
  width: 100%;
`;
const StBlockAttendanceDiv = styled.div`
  position: absolute;
  top: 34px;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
const StBlockTableDiv = styled.div`
  display: flex;
  z-index: 100;
`;
const StRowTabDiv = styled.div<{ isNotCursor: boolean }>`
  cursor: pointer;
  ${({ isNotCursor }) => isNotCursor && "cursor: default"};
`;
