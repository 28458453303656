import React, { FC, useMemo, useState } from "react";

import dayjs from "dayjs";
import styled from "styled-components";
import { IEventsOfDay } from "@interfaces/interface";
import { Colors } from "../../../constants/colors";

export const HEIGHT_BLOCK = 56;
export const WIDTH_BLOCK = 50;
export const BORDER_WIDTH = 1;

interface IProps {
  events: Partial<IEventsOfDay>[];
  isRooms?: boolean;
  setSelectedEvent?: (event: Partial<IEventsOfDay>) => void;
  selectedEvent?: Partial<IEventsOfDay>;
}

export const RowParticipant: FC<IProps> = ({
  events,
  isRooms,
  setSelectedEvent,
  selectedEvent,
}) => (
  <StContentWrapDiv>
    {events.map((event) => (
      <Event
        key={event?.id}
        event={event}
        isRooms={isRooms}
        setSelectedEvent={setSelectedEvent}
        selectedEvent={selectedEvent}
      />
    ))}
  </StContentWrapDiv>
);

const Event = ({
  event,
  isRooms,
  setSelectedEvent,
  selectedEvent,
}: {
  event: Partial<IEventsOfDay>;
  isRooms?: boolean;
  setSelectedEvent: (event: Partial<IEventsOfDay>) => void;
  selectedEvent: Partial<IEventsOfDay>;
}) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width =
      widthMinute * dayjs(event.endTime).diff(event.startTime, "minutes");
    const left =
      dayjs(event.startTime).hour() * WIDTH_BLOCK +
      widthMinute * dayjs(event.startTime).minute();
    const color = Colors.green500;

    return { width, left, color };
  }, [event.startTime, event.endTime]);

  return (
    <StEventItemDiv
      left={params.left}
      width={params.width}
      backColor={params.color}
      data-tooltip-id="user-booked-room"
      onMouseOver={() => setSelectedEvent(event)}
      onMouseOut={() => setSelectedEvent(null)}
    ></StEventItemDiv>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  height: ${HEIGHT_BLOCK + BORDER_WIDTH}px;
  width: 100%;
  position: relative;
`;
const StEventItemDiv = styled.div<{
  left: number;
  width: number;
  backColor: string;
}>`
  bottom: 0;
  border-radius: 4px;
  position: absolute;
  top: 2px;
  ${({ left, width, backColor }) => `
  left: ${left}px;
  width: ${width}px;
  background-color: ${backColor};
  z-index: 2;
`}
`;

const StInfoIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;
