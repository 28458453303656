import React, { HTMLAttributes, useMemo } from "react";

type TFont = "bold" | "regular" | "italic" | "thin";

type TProps = HTMLAttributes<HTMLSpanElement> & {
  className?: string;
  type?: TFont;
  size?: number | string;
  align?: "start" | "end" | "left" | "right" | "center";
  weight?: "700" | "400" | "500" | "800";
  color?: string;
  lineHeight?: number;
};

export const TextFont = ({
  type = "regular",
  weight,
  size,
  lineHeight,
  color,
  align,
  ...props
}: TProps) => {
  const styleFont = useMemo(() => {
    switch (type) {
      case "bold": {
        return "Roboto-Bold";
      }
      case "italic": {
        return "Roboto-Italic";
      }
      case "regular": {
        return "Roboto-Regular";
      }
      case "thin": {
        return "Roboto-Thin";
      }
      default: {
        return "Roboto-Regular";
      }
    }
  }, [type]);

  return (
    <div
      className={props.className}
      {...props}
      style={{
        color: color,
        fontSize: size ?? 14,
        fontWeight: weight,
        textAlign: align ?? "left",
        fontFamily: `${styleFont}, sans-serif`,
        wordBreak: "break-word",
        whiteSpace: "pre-line",
        ...(lineHeight && { lineHeight: `${lineHeight}px` }),
        ...props.style,
      }}
    />
  );
};
