import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { toFormatISO } from "@utils/toFormatTime";
import { useTime } from "./useTime";
import { IStartData } from "./ActivityUsers";
import { ICommonSlot, TStringUnknownTupleArray } from "@interfaces/interface";

export const useData = (startData: IStartData) => {
  const { startTime, endTime, handleTime } = useTime({
    startValue: startData?.startTime,
    endValue: startData?.endTime,
  });

  const [data, setData] = useState(startData?.data);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);
  const [isOpenCommonSlot, setOpenCommonSlot] = useState(false);
  const [participantLight, setParticipantLight] = useState("");
  const [meetingDurationMins, setMeetingDurationMins] = useState(0);
  const [commonSlot, setCommonSlot] = useState<ICommonSlot | null>(null);

  function isStringUnknownTupleArray(
    arg: unknown
  ): arg is TStringUnknownTupleArray {
    if (!Array.isArray(arg)) {
      return false;
    }

    for (const tuple of arg) {
      if (
        !Array.isArray(tuple) ||
        tuple.length !== 2 ||
        typeof tuple[0] !== "string" ||
        !Array.isArray(tuple[1])
      ) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (startData?.emails) {
      getCommonSlot();
    }
  }, [startTime, endTime, startData]);

  useEffect(() => {
    const diffMinutes = dayjs(endTime).diff(dayjs(startTime), "minutes");

    setMeetingDurationMins(diffMinutes);
  }, [startTime, endTime]);

  useEffect(() => {
    setIsEdit(
      startTime !== startData?.startTime ||
        endTime !== startData.endTime ||
        !isEqual(startData?.data?.participants, data?.participants) ||
        !isEqual(startData?.data?.externalUsers, data?.externalUsers) ||
        Boolean(false)
    );
  }, [data?.participants, data?.externalUsers, startTime, endTime, startData]);

  const { host } = window.location;

  let baseURL;
  switch (true) {
    case host.includes("dev") || host.includes("localhost"):
      // baseURL = "https://api.dt.dev.tap2v.com";
      baseURL = "https://calendar.dt.dev.tap2v.com";
      break;
    case host.includes("test"):
      // baseURL = "https://api.dt.test.tap2v.com";
      baseURL = "https://calendar.dt.test.tap2v.com";
      break;
    default:
      baseURL = "https://app.dogmatime.ru";
  }
  function getCommonSlot() {
    const base64data = window.btoa("admin:xgt5rjVM");
    const headers = {
      Authorization: `Basic ${base64data}`,
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };

    fetch(
      `${baseURL}/api-gateway/basic/v1/meetings/common-slot?startTime=${toFormatISO(
        dayjs(startTime)
      )}&endTime=${toFormatISO(dayjs(endTime).add(1, "month"))}&emails=${
        startData.emails
      }&duration=${dayjs(endTime).diff(startTime, "minutes")}`,
      {
        method: "POST",
        headers: headers,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCommonSlot(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleData = (
    name: string | TStringUnknownTupleArray,
    value: unknown
  ) => {
    if (typeof name === "string") {
      setData({
        ...data,
        [name]: value,
      });

      return;
    }

    if (isStringUnknownTupleArray(name)) {
      const updation = { ...data };

      for (const item of name) {
        updation[item[0]] = item[1];
      }

      setData(updation);
    }
  };

  const pressCommonSlot = () => {
    if (
      !dayjs(startTime).isSame(dayjs(commonSlot?.startTime)) ||
      !dayjs(endTime).isSame(dayjs(commonSlot?.endTime))
    ) {
      setOpenCommonSlot(true);
    }
  };

  const handleSetTimeFromCommonSlot = () => {
    const calculatedEndTime = dayjs(commonSlot?.startTime)
      .add(meetingDurationMins, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss");

    handleTime("startTime", commonSlot?.startTime);
    handleTime("endTime", calculatedEndTime);
    setOpenCommonSlot(false);
  };

  const formatData = useMemo(() => ({ ...data, startTime, endTime }), [
    startTime,
    endTime,
    data,
  ]);

  return useMemo(
    () => ({
      data,
      handleData,
      startTime,
      endTime,
      handleTime,
      isEdit,
      pressCommonSlot,
      handleSetTimeFromCommonSlot,
      isOpenCommonSlot,
      setOpenCommonSlot,
      isVisibleCalendar,
      setIsVisibleCalendar,
      participantLight,
      setParticipantLight,
      formatData,
      meetingDurationMins,
      commonSlot,
    }),
    [
      data,
      startTime,
      formatData,
      endTime,
      isOpenCommonSlot,
      isEdit,
      isVisibleCalendar,
      participantLight,
      meetingDurationMins,
      commonSlot,
    ]
  );
};
