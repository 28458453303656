import React from "react";

import Modal, { Props } from "react-modal";

interface IProps extends Props {
  isVisible: boolean;
  children: React.ReactNode;
  hasBackdrop?: boolean;
  onCancel?: () => void;
  style: any;
  onClose?: () => void;
}

export const NotificationUI = ({
  isVisible = false,
  children,
  onCancel,
  onClose,
  ...props
}: IProps) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCancel?.();
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      shouldCloseOnEsc
      {...props}
      style={{ content: { zIndex: 999, position: "absolute" } }}
      className={"notification"}
      onRequestClose={handleClose}
      appElement={document.getElementById("root") || undefined}
    >
      {children}
    </Modal>
  );
};
