import dayjs, { Dayjs } from "dayjs";

export const toFormatISO = (date: Dayjs): string =>
  date.format("YYYY-MM-DDTHH:mm:ss");

export const toFormatDate = (date: Dayjs): string => date.format("YYYY-MM-DD");

export const toFormatDayTime = (date: Dayjs): string =>
  date.format("DD.MM HH:mm");

export const toFormatDateRu = (date: Dayjs): string =>
  date.format("DD.MM.YYYY");

export const toFormatDateTimeRu = (date: Dayjs): string =>
  date.format("DD.MM.YYYY HH:mm");

export const toFormatYearMonth = (date: Dayjs): string =>
  date.format("YYYY-MM");

export const toFormatDayMonth = (date: Dayjs) => date.format("DD.MM");

export const toFormatHHmm = (date: Dayjs): string => date.format("HH:mm");

export const toFormatHHmmss = (date: Dayjs): string => date.format("HH:mm:ss");

export const toFormatStartTime = (date: Dayjs) => {
  const currMinute = dayjs().minute();
  const currHour = dayjs().hour();
  const periodForHour = currMinute > 30;
  const periodForMinute = currMinute > 0 && currMinute <= 30;

  const currTime = toFormatHHmm(
    dayjs()
      .hour(periodForHour ? currHour + 1 : currHour)
      .minute(periodForMinute ? 30 : 0)
  );
  const dayTimeNow = dayjs(`${toFormatDate(date)} ${currTime}`);
  const startTime = toFormatISO(dayTimeNow);
  const endTime = toFormatISO(dayTimeNow.add(1, "hour"));

  return { startTime, endTime };
};
