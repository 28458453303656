import React, { FC, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import { RenderWithCondition } from "@components/ui/RenderWithCondition";
import { HEIGHT_BLOCK, WIDTH_BLOCK } from "./RowParticipant";
import { Colors } from "../../../constants/colors";
import { TextFont } from "@components/ui/TextFont";

interface IRow {
  hour: number;
  date: Dayjs;
  rows: number;
  isLightColumnNumber: number;
  time: { startTime: string; endTime: string };
}

export const Row: FC<IRow> = ({
  hour,
  date,
  rows,
  isLightColumnNumber,
  time,
}) => {
  const currentMinute = dayjs().minute();

  const isCurrentHour = useMemo(
    () => hour === dayjs().hour() && date.isSame(dayjs(), "date"),
    [date, hour]
  );

  const dynamicPadding = useMemo(() => {
    if (currentMinute >= 0 && currentMinute <= 15) {
      return 0;
    }

    if (currentMinute > 15 && currentMinute <= 30) {
      return 7;
    }

    if (currentMinute > 30 && currentMinute <= 60) {
      return 15;
    }
  }, [currentMinute]);

  const isHourActive = useMemo(() => {
    const startTime = dayjs(time?.startTime);
    const endTime = dayjs(time?.endTime);
    return (
      (startTime.hour() === hour && startTime.minute() === 0) ||
      (endTime.hour() === hour && endTime.minute() === 0)
    );
  }, [time, hour]);

  const formatHour = useMemo(() => (hour < 10 ? `0${hour}` : hour), [hour]);

  return (
    <ContentWrap>
      <div style={{ pointerEvents: "none" }}>
        <TextHour size={14} isHourActive={isHourActive}>
          {formatHour}
        </TextHour>
      </div>

      <ColumnWrap>
        {[...Array(rows).keys()].map((i) => (
          <ColumnItem isActive={isLightColumnNumber === i} key={i}>
            <BlockLine>
              {Array.from({ length: rows * 9.5 }).map((_, i) => (
                <BlockLineElement key={i} />
              ))}
            </BlockLine>
          </ColumnItem>
        ))}
      </ColumnWrap>

      <RenderWithCondition condition={isCurrentHour}>
        <BlockCurrentTime paddingLeft={dynamicPadding}>
          <CircleTopTime />
          <LineTime />
        </BlockCurrentTime>
      </RenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  position: relative;
  width: ${WIDTH_BLOCK}px;
`;

const CircleTopTime = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: ${Colors.red};
`;

const LineTime = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${Colors.red};
`;

const BlockCurrentTime = styled.div<{ paddingLeft: number }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  z-index: 3;
  height: 300%;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
`;

const TextHour = styled(TextFont)<{ isHourActive: boolean }>`
  display: flex;
  align-items: end;
  margin-left: -5px;
  padding-bottom: 3px;
  width: ${WIDTH_BLOCK}px;
  height: 31px;
  top: 0;
  color: ${(props) =>
    props.isHourActive ? Colors.textAccent : Colors.textGrey400} !important;
  ${({ isHourActive }) => isHourActive && "font-weight: 700"};
`;

const ColumnWrap = styled.div`
  position: relative;
  border-top: 1px solid ${Colors.lightGrey};
`;

const ColumnItem = styled.div<{ isActive: boolean }>`
  height: ${HEIGHT_BLOCK}px;
  width: ${WIDTH_BLOCK}px;
  border-right: 1px solid ${Colors.lightGrey};
  border-top: 1px solid ${Colors.lightGrey};
  ${({ isActive }) =>
    isActive &&
    `background-color: ${Colors.lightGrey300};
   opacity: 0.5`};
`;

const BlockLine = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  height: 100%;
`;

const BlockLineElement = styled.div`
  width: 1.5px;
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: ${Colors.lightGrey400};
`;
