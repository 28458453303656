import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
//@ts-ignore
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";
//@ts-ignore
import { ReactComponent as ChevronUp } from "../../assets/chevron-up.svg";
import { RenderWithCondition } from "./RenderWithCondition";
import styled from "styled-components";
import { Colors } from "../../constants/colors";
// import { RenderWithCondition } from "@hoc";
// import globalState from "@utils/globalState";

interface IProps {
  value: string | number;
  onChange: (value: string, ...args: unknown[]) => string;
  style?: CSSProperties;
  disablePress?: boolean;
}

export enum EditTimeEvents {
  EditHours = "EditHour",
  EditMinutes = "EditMinutes",
}

export enum EditTimerOptions {
  StartTime = "startTime",
  EndTime = "endTime",
}

const validateTime = (value: string, eventType: EditTimeEvents): string => {
  const convertedValue = Number(value);

  if (isNaN(convertedValue)) {
    return "00";
  }

  if (eventType === EditTimeEvents.EditHours && convertedValue > 23) {
    return "23";
  }

  if (eventType === EditTimeEvents.EditMinutes && convertedValue > 59) {
    return "00";
  }

  // double conversion to trim extra zeros
  const result = convertedValue.toString();

  return result.length === 1 ? `0${result}` : result;
};

export const TimePicker: FC<IProps> = ({
  value,
  onChange,
  disablePress = false,
  style,
}) => {
  const splittedTime = value.toString().split(":");
  const initialHour = splittedTime[0];
  const initialMinutes = splittedTime[1];

  const [hours, setHours] = useState(initialHour);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [isHoursBtnsVisible, setIsHoursBtnsVisible] = useState(false);
  const [isMinutesBtnsVisible, setIsMinutesBtnsVisible] = useState(false);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);

  const increment = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    eventType: EditTimeEvents
  ) => {
    let nextValue: string;

    if (eventType === EditTimeEvents.EditHours) {
      nextValue = (parseInt(hours, 10) + 1).toString().padStart(2, "0");
      nextValue = nextValue > "24" ? "01" : nextValue;

      if (nextValue === "24") {
        nextValue = "00";
      }

      onChange(`${nextValue}:${minutes}`);
    }

    if (eventType === EditTimeEvents.EditMinutes) {
      nextValue = (parseInt(minutes, 10) + 15).toString().padStart(2, "0");

      nextValue = nextValue > "60" ? "01" : nextValue;

      if (nextValue === "60") {
        nextValue = "00";
      }

      onChange(`${hours}:${nextValue}`);
    }

    setter(nextValue);
  };

  const decrement = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    eventType: EditTimeEvents
  ) => {
    let nextValue: string;

    if (eventType === EditTimeEvents.EditHours) {
      nextValue = (parseInt(hours, 10) - 1).toString().padStart(2, "0");

      if (Number(nextValue) === -1) {
        setHours("23");
        return;
      }

      nextValue = nextValue < "01" ? "00" : nextValue;
      onChange(`${nextValue.toString()}:${minutes.toString()}`);
    }

    if (eventType === EditTimeEvents.EditMinutes) {
      nextValue = (parseInt(minutes, 10) - 15).toString().padStart(2, "0");

      if (Number(nextValue) === -1) {
        setMinutes("59");
        return;
      }

      nextValue = nextValue < "01" ? "00" : nextValue;
      onChange(`${hours}:${nextValue}`);
    }

    setter(nextValue);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
    eventType: EditTimeEvents
  ) => {
    if (event.key === "ArrowUp") {
      increment(setter, eventType);
    } else if (event.key === "ArrowDown") {
      decrement(setter, eventType);
    }

    if (event.key === "ArrowLeft") {
      hoursRef.current.focus();
      setIsMinutesBtnsVisible(false);
      setIsHoursBtnsVisible(true);
    } else if (event.key === "ArrowRight") {
      setIsMinutesBtnsVisible(true);
      setIsHoursBtnsVisible(false);
      minutesRef.current.focus();
    }
  };

  const handleInputChange = (value: string, eventType: EditTimeEvents) => {
    const validatedTime = validateTime(value, eventType);

    if (eventType === EditTimeEvents.EditHours) {
      const val = onChange(`${validatedTime}:${minutes}`);
      setHours(dayjs(val).format("HH"));
    }

    if (eventType === EditTimeEvents.EditMinutes) {
      const val = onChange(`${hours}:${validatedTime}`);
      setMinutes(dayjs(val).format("mm"));
    }
  };

  useEffect(() => {
    const splittedTime = value.toString().split(":");
    const parsedHours = splittedTime[0];
    const parsedMinutes = splittedTime[1];

    parsedHours !== hours && setHours(parsedHours);
    parsedMinutes !== minutes && setMinutes(parsedMinutes);
  }, [value]);

  return (
    <StContentWrapDiv>
      <StClockDiv
        onMouseEnter={() => setIsHoursBtnsVisible(true)}
        onMouseLeave={() => setIsHoursBtnsVisible(false)}
      >
        <RenderWithCondition condition={isHoursBtnsVisible && !disablePress}>
          <StButtonItemBtn
            onClick={() => increment(setHours, EditTimeEvents.EditHours)}
          >
            <ChevronUp />
          </StButtonItemBtn>
        </RenderWithCondition>
        <StInput
          disabled={disablePress}
          ref={hoursRef}
          // style={styles.input}
          value={hours}
          onKeyDown={(e) =>
            handleKeyDown(e, setHours, EditTimeEvents.EditHours)
          }
          onChange={(e) => {
            //@ts-ignore
            if (isNaN(e.target.value)) return;
            setHours(e.target.value);
          }}
          onBlur={() => {
            setIsHoursBtnsVisible(false);
            handleInputChange(hours, EditTimeEvents.EditHours);
          }}
          maxLength={2}
        />
        <RenderWithCondition condition={isHoursBtnsVisible && !disablePress}>
          <StButtonItemBtn
            onClick={() => decrement(setHours, EditTimeEvents.EditHours)}
          >
            <ChevronDown />
          </StButtonItemBtn>
        </RenderWithCondition>
      </StClockDiv>
      <span style={{ padding: "0 3px 0 3px" }}>&#58;</span>
      <StClockDiv
        onMouseEnter={() => setIsMinutesBtnsVisible(true)}
        onMouseLeave={() => setIsMinutesBtnsVisible(false)}
      >
        <RenderWithCondition condition={isMinutesBtnsVisible && !disablePress}>
          <StButtonItemBtn
            onClick={() => increment(setMinutes, EditTimeEvents.EditMinutes)}
          >
            <ChevronUp />
          </StButtonItemBtn>
        </RenderWithCondition>
        <StInput
          disabled={disablePress}
          ref={minutesRef}
          // style={styles.input}
          maxLength={2}
          value={minutes}
          onKeyDown={(e) =>
            handleKeyDown(e, setMinutes, EditTimeEvents.EditMinutes)
          }
          onChange={(e) => {
            //@ts-ignore
            if (isNaN(e.target.value)) return;
            setMinutes(e.target.value);
          }}
          onBlur={() => {
            setIsHoursBtnsVisible(false);
            handleInputChange(minutes, EditTimeEvents.EditMinutes);
          }}
        />
        <RenderWithCondition condition={isMinutesBtnsVisible && !disablePress}>
          <StButtonItemBtn
            onClick={() => decrement(setMinutes, EditTimeEvents.EditMinutes)}
          >
            <ChevronDown />
          </StButtonItemBtn>
        </RenderWithCondition>
      </StClockDiv>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
`;
const StClockDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35px;
  height: 40px;
  background-color: ${Colors.backgroundMain};
  padding: 8px 0;
  box-sizing: border-box;
  border-radius: 5px;
`;
const StButtonItemBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${Colors.lightGrey};
  width: 23px;
  border-radius: 3px;
  border: 1px solid ${Colors.placeholder};
  cursor: pointer;
`;
const StInput = styled.input`
  background-color: ${Colors.backgroundMain};
  text-align: center;
  padding: 3px 0 3px 0;
  width: 30px;
  border: none;
`;
