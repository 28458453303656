import React, { FC, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { TimePicker } from "@components/ui/TimePicker";

const toFormatHHmm = (date: Dayjs): string => date.format("HH:mm");
export const toFormatDate = (date: Dayjs): string => date.format("YYYY-MM-DD");

interface IProps {
  handleTime: (type: any, arg: string | Dayjs) => string;
  value: string;
  type: any;
  disablePress?: boolean;
}

export const Time: FC<IProps> = ({
  handleTime,
  value,
  type,
  disablePress = false,
}) => {
  const [localTime, setLocalTime] = useState(toFormatHHmm(dayjs(value)));

  useEffect(() => {
    setLocalTime(toFormatHHmm(dayjs(value)));
  }, [value]);

  const onChangeTime = (valueInput: string) => {
    setLocalTime(valueInput);

    if (valueInput.length === 5) {
      return handleTime(
        type,
        dayjs(`${toFormatDate(dayjs(value))} ${valueInput}`)
      );
    }
  };

  return (
    <>
      <TimePicker
        value={localTime}
        onChange={onChangeTime}
        disablePress={disablePress}
      />
    </>
  );
};
