import React, { CSSProperties, FC, useState } from "react";
import { TextFont } from "./TextFont";
import { NotificationUI } from "./NotificationUI";
import { Props } from "react-modal";
import { RenderWithCondition } from "./RenderWithCondition";
import styled from "styled-components";
import { Colors } from "../../constants/colors";

interface IProps extends Props {
  textTile: string;
  subTextTile?: string;
  phraseOk?: string;
  phraseCancel?: string;
  onOk: (() => void) | undefined;
  onCancel: (() => void) | undefined;
  onClose?: () => void;
  styleTextOk?: any;
  styleTextCancel?: any;
  styleContent?: CSSProperties;
  isHideButtons?: boolean;
}

export const NotificationConfirm: FC<IProps> = ({
  onOk,
  onCancel,
  textTile,
  subTextTile,
  phraseOk = "Да",
  phraseCancel = "Нет",
  styleTextOk = {},
  styleTextCancel = {},
  onClose,
  styleContent = {},
  isHideButtons = false,
  ...props
}) => {
  return (
    <NotificationUI
      isVisible
      onCancel={onCancel}
      onClose={onClose}
      style={modalWrapper}
      isOpen
      {...props}
    >
      <StWrapSection style={{ ...styleContent }}>
        <TextFont
          size={18}
          color={Colors.textMain}
          align="center"
          style={{ padding: 16 }}
        >
          {textTile}
        </TextFont>
        <RenderWithCondition condition={!!subTextTile}>
          <TextFont size={18} color={Colors.textMain} align="center">
            {subTextTile}
          </TextFont>
        </RenderWithCondition>

        <RenderWithCondition condition={!isHideButtons}>
          <StLineButtonsDiv>
            <StWrapButtonDiv>
              <StButtonItemBtn onClick={onCancel}>
                <TextFont
                  size={18}
                  color={Colors.textAccent}
                  align="center"
                  style={{ ...styleTextCancel }}
                >
                  {phraseCancel}
                </TextFont>
              </StButtonItemBtn>
            </StWrapButtonDiv>
            <StWrapButtonDiv>
              <StButtonItemBtn onClick={onOk}>
                <TextFont
                  weight="700"
                  size={18}
                  color={Colors.textAccent}
                  align="center"
                  style={{ ...styleTextOk }}
                >
                  {phraseOk}
                </TextFont>
              </StButtonItemBtn>
            </StWrapButtonDiv>
          </StLineButtonsDiv>
        </RenderWithCondition>
      </StWrapSection>
    </NotificationUI>
  );
};

const StWrapSection = styled.section`
  background-color: ${Colors.white};
  border-radius: 14px;
  width: 400px;
`;
const StLineButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  border-top: 0.5px solid ${Colors.borderDark};
`;
const StWrapButtonDiv = styled.div`
  width: 50%;
  flex-shrink: 0;
  flex-grow: 1;
  border-right: 0.5px solid ${Colors.borderDark};
`;
const StButtonItemBtn = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background-color: none;
`;
const modalWrapper = {
  display: "flex",
  "flex-direction": "column",
  alignItems: "center",
  justifyContent: "center",
};
