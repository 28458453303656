import React, { useMemo } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { WIDTH_BLOCK } from "./RowParticipant";
import { Colors } from "../../../constants/colors";
export const BlockTime = ({
  time,
}: {
  time: { startTime: string; endTime: string };
}) => {
  const params = useMemo(() => {
    const widthMinute = WIDTH_BLOCK / 60;
    const width =
      widthMinute * dayjs(time.endTime).diff(time.startTime, "minutes");
    const left =
      dayjs(time.startTime).hour() * WIDTH_BLOCK +
      widthMinute * dayjs(time.startTime).minute();
    const color = Colors.grey400;

    return { width, left, color };
  }, [time.endTime, time.startTime]);

  return (
    <BlockTimeItem
      width={params.width}
      left={params.left}
      backColor={params.color}
    />
  );
};

const BlockTimeItem = styled.div<{
  left: number;
  width: number;
  backColor: string;
}>`
  display: flex;
  height: 100%;
  top: 0;
  position: absolute;
  ${({ left, width, backColor }) => `
  left: ${left}px;
  width: ${width}px;
  background-color: ${backColor}; 
`}
`;
