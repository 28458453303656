// eslint-disable-next-line no-unused-vars
import React, { FC } from "react";

interface IProps {
  condition: any;
  children: JSX.Element;
  elseComponent?: JSX.Element;
}

export const RenderWithCondition: FC<IProps> = ({
  condition,
  children,
  elseComponent,
}) => {
  if (!condition) {
    return elseComponent ?? null;
  }

  return children;
};
