import React, { FC } from "react";

import styled from "styled-components";
import {
  ICommonFields,
  IParticipantsAttendanceOfDay,
} from "@interfaces/interface";
import { Participants } from "./Participants";

interface IProps extends ICommonFields {
  data: IParticipantsAttendanceOfDay[];
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  startTime: string;
  endTime: string;
}

export const TabletParticipants: FC<IProps> = ({
  data,
  setParticipantLight,
  participantLight,
  startTime,
  endTime,
}) => {
  return (
    <ContentWrap id="listParticipant">
      <Participants
        participantLight={participantLight}
        data={data}
        setParticipantLight={setParticipantLight}
        startTime={startTime}
        endTime={endTime}
      />
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  position: relative;
`;
